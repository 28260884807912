import { useNavigate } from "react-router-dom";
import { useCurrentUser } from "context/current-user-provider";
import { getUserInfo } from "services/http-requests";
import { toast } from "react-toastify";

const useGetUserInfo = () => {
  const { setCurrentUser } = useCurrentUser();

  const navigate = useNavigate();

  const userInfo = () => {
    getUserInfo()
      .then(({ data }) => {
        setCurrentUser(data.object);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          toast.warning("کاربر احراز هویت نشده است");
          localStorage.removeItem("auth-user");
          navigate("/login");
          return;
        }
        if (error.response && error.response.status === 404) return toast.error("خطا در دریافت اطلاعات کاربر !");

        return toast.error("خطا در هنگام اتصال به شبکه !");
      });
  };

  return {
    userInfo,
  };
};

export default useGetUserInfo;
