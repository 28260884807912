import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

axios.interceptors.request.use((request) => {
    request.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem("auth-user"))}`;
    return request;
});

// "GET" Requests

export function logoutApi() {
    return axios.get("/account/logout/");
}

export function platformCrmRequestApi() {
    return axios.get("/crm/api/platforms/");
}

export function requestsListPerPlatform(platformUUID) {
    return axios.get(`/crm/api/process/${platformUUID}`);
}

export function get_plarform_crm_form(uuid) {
    return axios.get(`/crm/api/process/${uuid}`);
}

export function send_plarform_crm_form(process_id, data) {
    return axios.post(`/crm/api/process/request/${process_id}`, data);
}

export function createNewRequestApi(data) {
    return axios.post("crm/api/processrequest/", data);
}

export function myRequestDetailsApi(uuid) {
    return axios.get(`/crm/api/processrequest/${uuid}`);
}

export function leaderboardTopAccountsApi(searchParams) {
    return axios.get(`/account/api/leaderboard/?${searchParams}`);
}

export function leaderboardGrownAccountsApi(searchParams) {
    return axios.get(`/account/growth?${searchParams}`);
}

export function verifyPhoneNumber(phoneNumber) {
    return axios.get(`/account/user/verify?phone=${phoneNumber}`);
}

export function sendOtpCode(phoneNumber, otp) {
    return axios.get(`/account/user/update_phone?phone=${phoneNumber}&otp=${otp}`);
}

export function getPackage() {
    return axios.get("/dashboard/");
}

export function getUserInfo() {
    return axios.get("/account/user/current-user");
}

export function getPlatforms() {
    return axios.get("/platform");
}

export function getContract(uuid) {
    if (uuid) return axios.get(`/account/user/contract?platform=${uuid}`);

    return axios.get("/account/user/contract");
}

export function discountCodeValidation(packageId, discountCode) {
    return axios.get(`/orders/payment/check_coupon?package=${packageId}&coupon=${discountCode}`);
}

export function getPurchaseHistory() {
    return axios.get("/account/user/current-user/packages-history");
}

export function getIntroducedPeople() {
    return axios.get("/account/user/refferal-stats");
}

export function getAllNotifications(type, pageNumber) {
    if (type === undefined || type === null)
        return axios.get(`/dashboard/notifications?page=${pageNumber || 1}`);

    return axios.get(`/dashboard/notifications?type=${type}&page=${pageNumber || 1}`);
}

export function getReadNotification(uuid) {
    return axios.get(`/dashboard/notification?notification_uuid=${uuid}`);
}

export function getFactors() {
    return axios.get("/dashboard/factors");
}

export function getAllTickets(pageNumber) {
    if (pageNumber) return axios.get(`/dashboard/ticket?page=${pageNumber || 1}`);
    return axios.get(`/dashboard/ticket`);
}

export function getTicketDetails(uuid) {
    return axios.get(`/dashboard/ticket?uuid=${uuid}`);
}

export function getDepartments() {
    return axios.get("/dashboard/departments");
}

export function mentorshipData(fromDate, toDate) {
    return requestByFilteredDate(fromDate, toDate, "/mentorship");
}

export function userUnderLeadership(uuid) {
    return axios.get(`/dashboard/mentorship?uuid=${uuid}`);
}

export function requestByFilteredDate(fromDate, toDate, pathname) {
    if (fromDate && toDate) return axios.get(`/dashboard${pathname}?from=${fromDate}&to=${toDate}`);

    if (fromDate && !toDate) return axios.get(`/dashboard${pathname}?from=${fromDate}`);

    if (!fromDate && toDate) return axios.get(`/dashboard${pathname}?to=${toDate}`);

    return axios.get(`/dashboard${pathname}`);
}

export function getConnectReport(phone) {
    return axios.get(`/orders/payments/?phone=${phone}`);
}

export function getWalletBalance() {
    return axios.get("/crm/account-balance/?wallet_address=TXboFWYCi9NoyKVf5MfRXJDGvNuNu1biLq");
}

export function getTransactionHistory(params) {
    return axios.get(`/crm/api/wallet-transactions/?${params}`);
}

export function updateTransactionHistory() {
    return axios.get(`/crm/api/run-tasks`);
}

export function userSearch(query) {
    return axios.get(`/account/api/user-search/?query=${query}`);
}

// "POST" Request

export function createRequestApi(data, params) {
    if (!data) return axios.post(`crm/api/submitrequest/?${params}`);

    return axios.post(`crm/api/submitrequest/?${params}`, data);
}

export function sendContract() {
    return axios.post("/account/user/contract");
}

export function sendPayment(packageId, discountCode) {
    return axios.post("/orders/payment/start", {
        package: packageId,
        coupon_code: discountCode,
    });
}

export function sendReadAllNotifications() {
    return axios.post("dashboard/notifications");
}

export function sendTicket(ticket) {
    return axios.post("/dashboard/ticket", ticket, {
        headers: { "Content-Type": "multipart/form-data" },
    });
}

export function leaderboardAddIncomeApi(amount) {
    return axios.post("/account/income/", amount);
}

export function editUpworkPassportPic(id, data) {
    return axios.post(`/crm/api/kyc/${id}/`, data);
}

export function editPreplyDocuments(id, data) {
    return axios.post(`/crm/api/correction/${id}/`, data);
}

export function setPartnerCode(partnerCode) {
    return axios.post(`/account/set-partner-code/`, {
        code: partnerCode,
    });
}

export function sendTransaction(wallet, amount, reason, passcode) {
    return axios.post(`/crm/usdt/transaction/`, {
        receiver_address: wallet,
        amount: amount,
        reason: reason,
        passcode: passcode,
    });
}

export function getWalletOwner(address) {
    return axios.post(`/account/api/user-by-wallet/`, {
        wallet_address: address,
    });
}

// "PUT" Request
export function sendGeneralInformation(userInfo) {
    return axios.put("/account/user/current-user", userInfo);
}

export function sendDocuments(documents) {
    return axios.put("/account/user/current-user", documents);
}

export function sendBankDetails(data) {
    return axios.put("/account/user/current-user", data);
}

export function updateTicket(ticket) {
    return axios.put("/dashboard/ticket", ticket, {
        headers: { "Content-Type": "multipart/form-data" },
    });
}

export function allRequestApi(pageNumber) {
    if (pageNumber) return axios.get(`/crm/api/processrequest/?page=${pageNumber}`);

    return axios.get("/crm/api/processrequest/");
}

// "PATCH" Request
export function ticketRate(ticketUuid, rate) {
    return axios.options("/dashboard/ticket", {
        data: { ticket: ticketUuid, stars: rate },
    });
}

// "DELETE" Request

export function deleteTicket(ticketId, rating, feedback) {
    return axios.delete("/dashboard/ticket", { data: { uuid: ticketId, stars: rating, feedback } });
}
