import React from "react";

const Spinner = ({ text, className = "" }) => {
  return (
    <div
      className={`flex flex-col items-center justify-center h-96 ${className}`}
    >
      <div className="w-20 h-20 border-4 border-b-4 border-r-4 border-gray-100 rounded-full border-b-green-600 border-r-green-600 animate-spin"></div>
      <div className="mt-8 font-yekan-800"> {text}</div>
    </div>
  );
};

export default Spinner;
