import React, { useContext, createContext, useState } from "react";

const CurrentUserContext = createContext();

const CurrentUserContextProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);

    return (
        <CurrentUserContext.Provider value={{ setCurrentUser, currentUser }}>
            {children}
        </CurrentUserContext.Provider>
    );
};

export default CurrentUserContextProvider;

export const useCurrentUser = () => useContext(CurrentUserContext);
